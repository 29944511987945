import API from "dt-cvm-api";
import * as errors from '../constants/Errors';
import { EMPTY_STRING } from "../constants/Constants";

const buildErrorObject = (db, message) => {
  let errorObj = {};

  // C360 Errors
  if(db === 'C360') {
    errorObj = {
      errorType: errors.ERROR_PAGE_TYPE_C360,
      errorCode: EMPTY_STRING,
    };
    if (message === errors.API_MESSAGE_FAILED_TO_FETCH) {
      errorObj = {
        ...errorObj,
        message: errors.ERROR_PAGE_MESSAGE_C360_FAILED_TO_FETCH,
      };
    } else if (message === errors.API_MESSAGE_MISSING_KEYS) {
      errorObj = {
        ...errorObj,
        message: errors.ERROR_PAGE_MESSAGE_C360_MISSING_KEYS,
      };
    } else if (message.indexOf(errors.API_MESSAGE_BAD_REQUEST) > -1) {
      errorObj = {
        ...errorObj,
        message: errors.ERROR_PAGE_MESSAGE_C360_BAD_REQUEST,
      };
    } else {
      errorObj = {
        ...errorObj,
        message: "Something went wrong",
      };
    }
  }

  return errorObj;
};

export const APIRouter = async (db, fnName, params, setLoader = () => {}, isModal = false, setApplicationError = () => {}) => {
  try {
    //turn on the loader
    setLoader({ isLoading: true, isModal: isModal });

    let retval;
    if (Array.isArray(params)) {
      retval = await API[db][fnName](...params);
    } else {
      retval = await API[db][fnName](params);
    }
    return retval;
  } catch (e) {
    const errorObj = buildErrorObject(db, e?.message);
    setApplicationError({
      ...errorObj,
      errorStack: e?.stack
    });
    setLoader({ isLoading: false, isModal });
  } finally {
    setLoader({ isLoading: false, isModal });
  }
};
