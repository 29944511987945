import API from 'dt-cvm-api';
import { useContext, useEffect, useState } from 'react';
import { isArray, split } from 'lodash';

import StorageShim from 'node-storage-shim';

import { IoOptions } from 'react-icons/io5';
import styled from '../../../../helpers/esm-styled-components';
import * as constants from '../../../../constants/Constants';
import {
  HorizontalDivider, WizardButtons, ReturnToCustomerSummary, AddAdditionalVehicleInfo,
} from '../../ModalUtils';
import Dropdown from '../../../Dropdown/Dropdown';
import {
  colorOptions,
  countryOptions,
  getColorOption,
  getStateOrProvincePlaceholder,
  initialAssemblyDetails,
} from '../../../../helper/baseDataHelper';
import { APIContext } from '../../../../Data/API';
import GenericVehicleForm from './GenericVehicleForm';
import GenericVehicleAssembly from './GenericVehicleAssembly';
import {
  vehicleFormValidation, genericVehicleFieldValidations, formatTireWidth, formatTireDiameter, formatTireRatio,
} from '../../../../helper/vehicleFormValidation';
import { CVMContext } from '../../../../components/CVM/CVM';
import { addUpdateCustomerGenericVehicle } from '../StandardVehicle/VehicleHelper';
import { isEmptyObject, isNullEmptyUndefined } from '../../../../helper/validationHelper';

const localStorage = globalThis.localStorage || new StorageShim();

const VehicleCategoryContainer = styled.div`
//Adjust based on the trigger of more detail information
  height:auto;
`;
const VehicleCategoryGrid = styled.div`
  min-height: 60px;
  margin: ${({ formOpen }) => (formOpen ? '0 24px 30px 0;' : '0 24px 50px 0;')};
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(2,335px);
  grid-template-rows: 1;
  grid-template-areas: 'category subcategory';
  align-content: center;
  justify-content: center;
`;
const VehicleCategory = styled(Dropdown)`
grid-area: "category";
`;
const VehicleSubCategory = styled(Dropdown)`
grid-area: "subcategory";
z-index: 20;
`;
const GenericVehicleInit = (props) => {
  // props
  const {
    returnToCustomerSummary, vehicleDetails, tireDetails, tireFields, setTireFields, fields, arChargeFields, setFields, useImportedVehicle,
  } = props;
  // context
  const {
    getStateOptions,
    getStateOrProvince,
    getVehicleCategories,
  } = useContext(APIContext);
  const {
    selectedVehicle,
    setSelectedVehicle,
    moduleName,
    setModuleName,
    setLoader,
    customerInfo,
    setIsModalOn,
    setImportedVehicle,
    vtvSelected,
    aircheckSelected,
    setVtvSelected,
    setAircheckSelected,
    setApplyCustomerVehicleError,
    transactionCustomer,
  } = useContext(CVMContext);

  // states
  const [subCategories, setSubCategories] = useState(null);
  const [assemblies, setAssemblies] = useState({});
  const [formOpen, setFormOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [notesOpen, setNotesOpen] = useState(!!vehicleDetails?.notes);
  const [requiredMet, setRequiredMet] = useState(false);
  // const [isEdit] = useState(!useImportedVehicle && vehicleDetails && tireDetails);
  //  TODO: Wildly overcomplicated given that you can't edit if there is no
  //  vehicle ID and you can't add if there is.
  const [isEdit] = useState(
    !API.utils.isTruthy(useImportedVehicle) &&
    API.utils.isValid(vehicleDetails) &&
    API.utils.isValid(tireDetails));

  /**
   * Generic Vehicle Subcategories Dropdown items
   * @param {Object} options
   * @returns an array of generic vehicle subcategory objects
   */
  const vehicleSubCategories = (options) => options?.map(option => (
    { value: option.value, label: option.label, image: option.image || constants.EMPTY_STRING  }
  ));

  const getCountryDropdownValue = (vehicle) => {
    let countryCode;

    if (API.utils.notEmpty(vehicle?.licenseCountry)) {
      if (API.utils.isString(vehicle.licenseCountry)) {
        countryCode = API.transforms.collapseCountry(vehicle?.licenseCountry);
      } else {
        return vehicle?.licenseCountry;
      }
    } else {
      countryCode = API.transforms.collapseCountry(
        vehicle?.licensePlateCountryCode || constants.US);
    }

    const label = API.transforms.expandCountry(countryCode);

    return { label, value: countryCode };
  };

  const getStateDropdownValue = (vehicle) => {

    let stateCode,
      countryCode;

    if (API.utils.notEmpty(vehicle?.licenseState)) {
      if (API.utils.isString(vehicle.licenseState)) {
        countryCode = getCountryDropdownValue(vehicle)?.value || constants.US;
        stateCode = API.transforms.collapseState(vehicle.licenseState);
      } else {
        return vehicle.licenseState;
      }
    } else {
      countryCode = getCountryDropdownValue(vehicle).value;
      stateCode = API.transforms.collapseState(
        vehicle?.licensePlateStateCode || localStorage.getItem('siteState'),
        countryCode);
    }

    //  See getStateOrProvince - for legacy reasons, returns an Array.
    return getStateOrProvince(countryCode, stateCode)[0];
  };

  /**
   * Establishes the vehicle object to be used in the generic vehicle form
   * @param {Object} vehicle
   * @returns the generic vehicle object
   */
  const initialGenericVehicleFormData = (vehicle) => {

    // NOTE: PB is sending the wrong value it should be LAWN & GOLF adding a patch remove when fixed
    if (vehicle?.overrideVehicleCategory === 'LAWN GOLF') vehicle.overrideVehicleCategory = 'LAWN & GOLF';
    if (vehicle?.licenseCountry === constants.CAN) vehicle.licenseCountry = constants.CA;
    const formData = {
      id: vehicle?.id || vehicle?.vehicleId || constants.EMPTY_STRING,
      vehicleCategory: vehicle?.overrideVehicleCategory || constants.EMPTY_STRING,
      vehicleSubCategory: vehicle?.overrideVehicleSubCategory || constants.EMPTY_STRING,
      year: vehicle?.overrideYear || constants.EMPTY_STRING,
      make: vehicle?.overrideMake || constants.EMPTY_STRING,
      model: vehicle?.overrideModel || constants.EMPTY_STRING,
      trim: vehicle?.overrideTrim || constants.EMPTY_STRING,
      color: getColorOption(vehicle?.color)[0] || constants.EMPTY_STRING,
      licensePlate: vehicle?.licensePlateNumber || constants.EMPTY_STRING,

      //  NOTE: We do this *before* state, because state depends on country.
      licenseCountry: getCountryDropdownValue(vehicle),
      licenseState: getStateDropdownValue(vehicle),

      vin: vehicle?.vin || constants.EMPTY_STRING,
      oneTimeVehicle: false,
      imageUrl: vehicle?.imageUrl || constants.EMPTY_STRING,
    };
    return formData;
  };

  const setFormFields = (vehicleDetails, tireDetails) => {
    setFields(initialGenericVehicleFormData(vehicleDetails));
    setTireFields(initialAssemblyDetails(tireDetails));
  };

  useEffect(() => {
    if (isEdit) {
      tireDetails.vehicleTireData[0].isStaggered = vehicleDetails.isStaggered || false;
      tireDetails.vehicleTireData[0].isDually = vehicleDetails.isDually ||
        vehicleDetails.isDualRearWheel || false;
      tireDetails.vehicleTireData[0].notes = vehicleDetails.notes || '';
    }

    setFormFields(vehicleDetails, tireDetails);
  }, []);

  useEffect(() => {
    if (fields?.vin === constants.EMPTY_STRING) {
      setErrorMessage(null);
    }
    const item = getVehicleCategories().filter(
      (option) => option.label.toLowerCase() === fields?.vehicleCategory?.toLowerCase(),
    );
    setSubCategories(item[0]?.subCategories.length ? item[0].subCategories : null);
    if (fields?.vehicleCategory && !vehicleSubCategories(subCategories)) {
      setFormOpen(true);
    } else if (fields?.vehicleCategory && fields?.vehicleSubCategory
       && vehicleSubCategories(subCategories)?.length) {
      setFormOpen(true);
    } else {
      setFormOpen(false);
    }
    const getAssemblies = async () => {
      const assemblyObj = await API.FIT.getGenericAssemblies({ vehicleCategory: fields?.vehicleCategory, vehicleSubcategory: fields?.vehicleSubCategory || '' });
      // NOTE: The objects of arrays need to be sorted min-max, then filtered for duplicates before converting to object with value label
      Object.keys(assemblyObj).forEach((key) => { assemblyObj[key] = assemblyObj[key].map((value) => { return parseFloat(value); }); });
      Object.keys(assemblyObj).forEach((key) => { assemblyObj[key].sort((a, b) => a - b); });
      Object.keys(assemblyObj).forEach((key) => { assemblyObj[key] = assemblyObj[key].filter((item, index) => assemblyObj[key].indexOf(item) === index); });
      setAssemblies(assemblyObj);
    };
    if (fields?.vehicleCategory && (subCategories === null || fields?.vehicleSubCategory)) {
      getAssemblies();
    }
    setRequiredMet(genericVehicleFieldValidations(
      tireFields?.assemblyConfiguration?.value,
      tireFields?.tireWidth,
      tireFields?.tireRatio,
      tireFields?.tireDiameter,
      tireFields?.rearTireWidth,
      tireFields?.rearTireRatio,
      tireFields?.rearTireDiameter,
    ));
  }, [fields, tireFields, subCategories, requiredMet, tireFields?.assemblyConfiguration]);

  /**
   * Click handler for all dropdown selections
   * @param {Object} value
   * @param {String} fieldName
   */
  const handleDropdown = (value, fieldName, options) => {
    let item;
    const updatedFields = { ...fields };
    switch (fieldName) {
      case 'licenseCountry':
        item = countryOptions.filter((option) => option.value === value.value);
        updatedFields.licenseState = constants.EMPTY_STRING;
        break;
      case 'licenseState':
        item = getStateOrProvince(fields?.licenseCountry?.value, value);
        break;
      case 'color':
        item = colorOptions.filter((option) => option.label === value.label);
        break;
      case 'vehicleCategory':
        item = getVehicleCategories().filter((option) => option.label === value.label);
        setSubCategories(item[0].subCategories ? item[0].subCategories : null);
        if (!item[0].subCategories) updatedFields.vehicleSubCategory = constants.EMPTY_STRING;
        setTireFields(initialAssemblyDetails);
        Object.keys(updatedFields).forEach((k) => {
          updatedFields[k] = constants.EMPTY_STRING;
        });
        if (item[0].image && !item[0].subCategories.length) updatedFields.imageUrl = item[0].image;
        setFields({ ...updatedFields, [fieldName]: item[0].label });
        return;
      case 'vehicleSubCategory':
        // TODO: Work around, only subcats seem to have a image
        updatedFields.imageUrl = value?.image || constants.EMPTY_STRING;
        if (!updatedFields.imageUrl)updatedFields.imageUrl = subCategories[subCategories.length - 1].image;
        item = [value.label];
        break;
      case 'tireWidth':
      case 'rearTireWidth':
      case 'tireDiameter':
      case 'rearTireDiameter':
      case 'tireRatio':
      case 'rearTireRatio':
        item = options?.filter(option => value.value.toString() === option.value.toString())[0].value;
        if (!item) { item = value; } else {
          setTireFields({ ...tireFields, [fieldName]: item?.toString() });
        }
        return;
      default:
        return null;
    }
    if (value && fieldName && item) {
      setFields({ ...updatedFields, [fieldName]: item[0] });
    }
  };

  /**
   *
   * @param {String} fieldName
   * @param {any} value
   */
  const clearDropdown = (fieldName, value) => {
    setFields({
      ...fields,
      [fieldName]: value,
    });
  };

  /**
   * Input handler for all inputs
   * @param {String} value
   * @param {String} fieldName
   */
  const handleInput = (value, fieldName) => {
    const inputValue = vehicleFormValidation(value, fieldName);
    setFields({ ...fields, [fieldName]: inputValue });
  };

  /**
   * Click handler for changing the assembly configuration
   * @param {Event} e
   */
  const handleAssemblyChange = (e) => {
    const item = constants.ASSEMBLY_VALUES.filter((option) => option.value === e.target.value);
    if (item) {
      setTireFields({ ...tireFields, assemblyConfiguration: item[0] });
    }
  };

  /**
   * Specific input handler for tire input changes
   * @param {String} value
   * @param {String} fieldName
   */
  const handleTireInput = (value, fieldName) => {
    const inputValue = vehicleFormValidation(value, fieldName);
    setTireFields({ ...tireFields, [fieldName]: inputValue });
  };

  //
  // Click handler for toggling one-time vehicle
  //
  const toggleClick = (e, name) => {
    switch (name) {
      case 'OneTime':
        setFields({ ...fields, oneTimeVehicle: !fields.oneTimeVehicle });
        break;
      case 'CarryOut':
        setFields({ ...fields, isCarryOut: !fields.isCarryOut });
        break;
      default: break;
    }
  };

  const handleDropdownBlur = (fieldName, value) => {
    if (!value) return;
    let newValue;
    switch (fieldName) {
      case 'tireWidth':
      case 'rearTireWidth': newValue = formatTireWidth(value);
        break;
      case 'tireDiameter':
      case 'rearTireDiameter': newValue = formatTireDiameter(value);
        break;
      case 'tireRatio':
      case 'rearTireRatio': newValue = formatTireRatio(value);
        break;
      default: break;
    }
    setTireFields({ ...tireFields, [fieldName]: newValue?.toString() });
  };

  const getAssembliesDropdownProps = (fieldName) => {
    if (isEmptyObject(assemblies)) return;
    const splitName = fieldName.split(/(?=[A-Z])/);
    const props = {
      fieldName,
      placeholder: splitName[splitName.length - 1],
      onChange: handleDropdown,
      value: tireFields?.[fieldName]?.toString(),
      required: true,
      dropdownHeight: !formOpen ? '160' : null,
      positionStyle: 'absolute',
      hasCustomInput: true,
      handleOnBlur: handleDropdownBlur,
    };
    let type = '';
    switch (fieldName) {
      case 'tireWidth':
      case 'rearTireWidth': type = 'crossSection';
        break;
      case 'tireDiameter':
      case 'rearTireDiameter': type = 'rimDiameter';
        break;
      case 'tireRatio':
      case 'rearTireRatio': type = 'aspectRatio';
        break;
      default: break;
    }
    // NOTE: dropdowns use objects, conversion from array to array with object
    props.options = assemblies[type].map((val) => { return { value: val.toString(), label: val.toString() }; });
    const { options } = props;
    props.selectedOption = options?.filter(assembly => assembly.value.toString() === tireFields[fieldName]?.toString())[0] || { value: tireFields?.[fieldName], label: tireFields?.[fieldName] };

    // temporary solution to fixed undefineds
    // TODO: Need to determine why undefined is coming back rather than empty string
    const { value, label } = props?.selectedOption;
    if (value === undefined || label === undefined) {
      props.selectedOption = { value: constants.EMPTY_STRING, label: constants.EMPTY_STRING };
    }
    return props;
  };
  //
  // Properties for the Country Dropdown
  //
  const categoryDropdownProps = {
    fieldName: 'vehicleCategory',
    placeholder: 'Vehicle Category',
    options: getVehicleCategories(),
    onChange: handleDropdown,
    selectedOption: getVehicleCategories().filter(c => c.label.toLowerCase() === fields?.vehicleCategory?.toLowerCase())[0],
    value: fields?.vehicleCategory?.value,
    required: true,
    dropdownHeight: !formOpen ? '160' : null
  };

  //
  // Properties for the Subcategory Dropdown
  //
  const subCategoryDropdownProps = {
    fieldName: 'vehicleSubCategory',
    placeholder: 'Vehicle Sub-Category',
    options: subCategories ? vehicleSubCategories(subCategories) : null,
    onChange: handleDropdown,
    selectedOption: categoryDropdownProps.selectedOption?.subCategories
      ? vehicleSubCategories(subCategories)?.filter(sub => sub.label.toLowerCase() === fields.vehicleSubCategory?.toLowerCase())[0]
      : constants.EMPTY_STRING,
    value: fields?.vehicleSubCategory?.value,
    required: true,
    dropdownHeight: !formOpen ? '160' : null
  };

  //
  // Properties for the Country Dropdown
  //
  const countryDropdownProps = {
    fieldName: 'licenseCountry',
    placeholder: 'Country',
    options: countryOptions,
    onChange: handleDropdown,
    selectedOption: fields?.licenseCountry || countryOptions[0],
    value: fields?.licenseCountry?.value,
    required: false,
    positionStyle: 'absolute',
  };

  //
  // Properties for the State Dropdown
  //
  const stateDropdownProps = {
    fieldName: 'licenseState',
    placeholder: getStateOrProvincePlaceholder(fields?.licenseCountry?.value || countryOptions[0].value),
    options: getStateOptions(fields?.licenseCountry?.value || countryOptions[0].value),
    onChange: handleDropdown,
    disabled: fields?.isCarryOut,
    // TO DO: Ask Brian, why default AZ
    selectedOption: fields?.isCarryOut ? { value: 'AZ', label: 'Arizona' } : fields?.licenseState,
    value: fields?.licenseState?.value,
    required: arChargeFields?.map(f => f.field).includes('LIC PLATE STATE'),
    positionStyle: 'absolute',
    onClickClear: () => clearDropdown('licenseState', constants.EMPTY_STRING),
  };
  const checkIfSubmitDisabled = () => {
    if (!arChargeFields) {
      return false;
    }
    if (fields?.isCarryOut) {
      let disabled = false;
      for (let i = 0; i < arChargeFields.length; i++) {
        if (arChargeFields[i].field !== 'VIN #' && arChargeFields[i].field !== 'LIC PLATE STATE' && arChargeFields[i].field !== 'LICENSE PLATE #') {
          disabled = (isNullEmptyUndefined(arChargeFields[i].value()));
          if (disabled) {
            break;
          }
        }
      }
      return disabled;
    }
    return arChargeFields?.some(f => isNullEmptyUndefined(f.value()));
  };


  // Click handler for opening the notes section
  const openNotes = () => {
    setNotesOpen(!notesOpen);
  };
  return (
    <VehicleCategoryContainer className="current-view-modal-generic">
      <VehicleCategoryGrid formOpen={formOpen}>
        <VehicleCategory {...categoryDropdownProps}/>
        {subCategories && <VehicleSubCategory {...subCategoryDropdownProps}/>}
      </VehicleCategoryGrid>
      {formOpen && (
        <>
          <GenericVehicleForm
            arChargeFields={arChargeFields}
            handleInput={handleInput}
            fields={fields}
            toggleClick={toggleClick}
            updateColor={handleDropdown}
            stateDropdownProps={stateDropdownProps}
            countryDropdownProps={countryDropdownProps}
            errorMessage={errorMessage}
            isEdit={isEdit}
            />
          <GenericVehicleAssembly
            assemblies={assemblies}
            getAssembliesDropdownProps={getAssembliesDropdownProps}
            handleInput={handleTireInput}
            handleChange={handleAssemblyChange}
            fields={tireFields}
            openNotes={openNotes}
            notesOpen={notesOpen} />
        </>
      )}
      <HorizontalDivider generic/>
      <WizardButtons generic>
        <ReturnToCustomerSummary
          buttonType={constants.DEFAULT}
          buttonName={constants.BUTTON_RETURN_TO_CUSTOMER_SUMMARY}
          isDisabled={false}
          onButtonClick={returnToCustomerSummary} />
        <AddAdditionalVehicleInfo
          buttonType={constants.DEFAULT}
          buttonName={!isEdit ? constants.BUTTON_ADD_VEHICLE_TO_CUSTOMER : constants.BUTTON_SAVE_VEHICLE_EDITS}
          isDisabled={!requiredMet || checkIfSubmitDisabled()}
          onButtonClick={(requiredMet && !checkIfSubmitDisabled()) ? () => addUpdateCustomerGenericVehicle(setErrorMessage, tireFields, fields,
            vehicleDetails, tireDetails, isEdit, setLoader, setIsModalOn,
            moduleName, setModuleName, selectedVehicle, setSelectedVehicle,
            customerInfo, true, setImportedVehicle, useImportedVehicle,
            setFormFields, vtvSelected || aircheckSelected, setVtvSelected, setAircheckSelected, transactionCustomer, setApplyCustomerVehicleError) : () => {}} />
      </WizardButtons>
    </VehicleCategoryContainer>
  );
};
export default GenericVehicleInit;
