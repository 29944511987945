import React, { useState, useEffect, useContext } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import { CVMContext } from '../../CVM/CVM';
import styled from '../../../helpers/esm-styled-components';
import { theme } from '../../../helpers/GlobalStyles';
import * as constants from '../../../constants/Constants';
import Vehicle from './Vehicle';
import { TitleVBar } from '../DetailSection';
import TireInformationDetails from '../Tire/TireInformationDetails';
import VehicleDiagram from './VehicleDiagram';
import { formatNumeric, formatAlphaNumeric } from '../../../helper/validationHelper';
import { maxTreadDepthHandler } from '../../../helper/vehicleDetailsHelper';
import { isGenericVehicle, validTreadFields } from '../../../helper/vehicleFormValidation';
import { objectsAreEqual } from '../../../helper/baseDataHelper';

const VehicleContainer = styled.div`
  //Conditionally render if it is a VTV Aircheck
  ${({ isVTVAircheck }) => isVTVAircheck && 'display:flex;'}
`;
const VehicleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  margin: -10px 70px 0 0;
  max-width: 821px;
  ${({ isVTVAircheck }) => isVTVAircheck && 'margin:0; width:fit-content;height: auto;'};
`;
const VehicleInfoWrapper = styled.div`
`;
const VehicleDetailsContainer = styled.div`
  display: inline-flex;
  margin-right: 30px;
  margin-top: 5px;
`;
export const OEBadgeWrapper = styled.div`
  position: absolute;
  margin-top: ${({ isRear }) => (isRear ? '-5px' : '10px')};
`;
export const OEBadge = styled.p`
  background-color: ${({ name }) => (name === constants.VEHICLE_OE_LABEL ? theme.colors.red : name === constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL ? theme.colors.orange : theme.colors.editBlue)};
  padding: 10px 8px;
  border-radius: 2.5px;
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.semiLarge};
  margin-top: 15px;
  margin-left: ${({ name, isStaggered }) => ((name === constants.VEHICLE_WTR_LABEL && !isStaggered) ? '-5px' : (isStaggered && name === constants.VEHICLE_WTR_LABEL) && '-15px')};
`;
export const FactoryTireSize = styled.p`
  margin-left: 43px;
  text-align: right;
  width: max-content;
  font-size: ${theme.fontSize.xLarge};
  font-family: ${theme.fontFamily.black};
  color: ${theme.colors.darkGray};
  margin-top: 23px;
`;
export const SmallVerticalBar = styled.div`
  height: 45px;
  margin-top: 24px;
  margin-left: 20px;
  position: absolute;
  border-right: 2px solid ${theme.colors.verticalDivider};
`;
export const LargeVerticalBar = styled.div`
  height: 125px;
  margin-top: 24px;
  margin-left: 20px;
  position: absolute;
  border-right: 2px solid ${theme.colors.verticalDivider};
  width: 1px;
`;
export const WheelSize = styled.p`
  margin-left: 5px;
  font-size: ${theme.fontSize.xLarge};
  font-family: ${theme.fontFamily.black};
  color: ${theme.colors.darkGray};
`;
export const SelectedVehicleLabelWrapper = styled.div`
  text-align: center;
  position: relative;
  width: fit-content;
  margin: -10px auto 15px auto;
`;
export const SelectedVehicleLabel = styled.p`
  margin-top: -15px;
  text-align: center;
  font-size: ${theme.fontSize.infoSubtitle};
  text-transform: uppercase;
  font-weight: bold;
  display: inline;
`;
export const FactoryTireSizeWrapper = styled.div`
  margin-left: ${({ $isWinter }) => ($isWinter ? '25px' : '10px;')};
  margin-top: 20px;
`;
export const FactoryTireSizeLabel = styled.p`
  color: ${theme.colors.lightGray};
  margin-top: -22px;
  text-align: right;
  margin-left: 43px;
  width: max-content;
`;
export const WheelSizeLabel = styled.p`
  color: ${theme.colors.lightGray};
  margin-top: 48px;
  position:absolute;
  margin-left: 40px;
`;
const TireDetailsWrapper = styled.div`
  margin-left: 40px;
  margin-top: 25px;
`;
const TireDetailsLabel = styled.p`
  font-size: ${theme.fontSize.editLabel};
  width: max-content;
  margin: 12px 0;
`;
const TireDetailsValue = styled.span`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.editLabel};
`;
export const SelectedVehicleEditLabel = styled.span`
  margin-left: 15px;
  color: ${theme.colors.editBlue};
  font-size: ${theme.fontSize.editLabel};
  font-family: ${theme.fontFamily.normal};
  cursor: pointer;
  ${({ isHidden }) => isHidden && 'color: grey; cursor: not-allowed;'}
`;
const VehicleVBar = styled(TitleVBar)`
  height: 100%;
  margin: 0;
`;

// Icons
export const EditIcon = styled(FaRegEdit)`
  font-size: ${theme.fontSize.large};
  margin-left: 5px;
`;
export const SelectedVehicleCheck = styled(AiFillCheckCircle)`
  color: ${theme.colors.green};
  font-size: ${theme.fontSize.xLarge};
  position: absolute;
  top: 2px;
  left: -25px;
`;
const VehicleDetails = props => {
  const {
    selectedVehicle, isVTVAircheck, inspectionFields, setInspectionFields, initialInspection, editVehicle, isBlocked, isHidden
  } = props;
  const {
    setVtvCanSave
  } = useContext(CVMContext);

  const isGeneric = isGenericVehicle(selectedVehicle?.vehicleDetails)
  || selectedVehicle?.vehicleDetails?.overrideDescription === constants.VEHICLE_TYPE_GENERIC_VEHICLE;
  const [isStaggered, setIsStaggered] = useState(selectedVehicle?.vehicleDetails?.isStaggered);
  const [isOE, setIsOE] = useState((selectedVehicle?.vehicleDetails?.assemblyCollection?.filter(ac => ac.assemblyItem.currentAssembly)[0]?.assemblyItem?.assemblyType || selectedVehicle?.vehicleDetails?.assemblyCollection?.[0]?.assemblyItem?.assemblyType) === constants.VEHICLE_OE_ASSEMBLY_TYPE);
  const [isAfterMarket, setIsAfterMarket] = useState((selectedVehicle?.vehicleDetails?.assemblyCollection?.filter(ac => ac.assemblyItem.currentAssembly)[0]?.assemblyItem?.assemblyType || selectedVehicle?.vehicleDetails?.assemblyCollection?.[0]?.assemblyItem?.assemblyType) === constants.VEHICLE_ALT_ASSEMBLY_TYPE);
  const isDually = selectedVehicle?.vehicleDetails?.isDually || selectedVehicle?.vehicleDetails?.isDualRearWheel || false;
  const handleInput = (value, fieldName) => {
    const tireIndex = fieldName.slice(0, 1);
    const treadIndex = fieldName.slice(fieldName.length - 1);
    const updatedFields = { ...inspectionFields };
    if (/^\d+$/.test(treadIndex)) {
      setInspectionFields({ ...inspectionFields, [fieldName]: value });
      updatedFields.tireCollection[tireIndex].tireItem.treadDepthCollection[treadIndex].treadDepthItem.reading = maxTreadDepthHandler(formatNumeric(value)) || constants.EMPTY_STRING;
    } else {
      fieldName = fieldName.replace(/[0-9]/g, '');
      if (fieldName === 'dotNumber' || fieldName === 'tireAge') {
        updatedFields.tireCollection[tireIndex].tireItem[fieldName] = fieldName === 'dotNumber' ? formatAlphaNumeric(value).toUpperCase() : value.toString();
      } else {
        if (value?.label) {
          if (fieldName === 'isSpareInUse') {
            value = value.value;
            updatedFields[fieldName] = value;
          } else {
            value = value.label;
            updatedFields[fieldName] = value;
          }
        }
        updatedFields[fieldName] = value;
      }
    }
    setInspectionFields({ ...updatedFields });
  };

  const isAssemblyPropertyDiff = () => {
    const vehicleDetails = selectedVehicle?.tireDetails?.vehicleDetails || selectedVehicle?.tireDetails?.vehicleTireData;
    return (vehicleDetails?.length >= 2 && (vehicleDetails[0]?.psi !== vehicleDetails[1]?.psi || vehicleDetails[0].offset !== vehicleDetails[1].offset || vehicleDetails[0].wheelRimWidth !== vehicleDetails[1].wheelRimWidth));
  };

  const isEmptyOrNull = (vehicleDetails) => {
    return !!((!vehicleDetails || vehicleDetails === '' || vehicleDetails === '0'));
  };

  const isAssemblyPropertySingleIndexDiff = () => {
    const vehicleDetails =
      selectedVehicle?.tireDetails?.vehicleDetails ||
      selectedVehicle?.tireDetails?.vehicleTireData;

    if (vehicleDetails?.length === 1) {
      if (!isGeneric) {
        return vehicleDetails[0]?.frontTirePressure !== vehicleDetails[0]?.rearTirePressure;
      }
      return (vehicleDetails[0]?.frontTirePressure !== vehicleDetails[0]?.rearTirePressure || vehicleDetails[0]?.frontRimSize !== vehicleDetails[0]?.rearRimSize);
    }
  };

  const loadAssemblyDetails = (vehicleDetails) => {
    const getGenericVehWheelWidth = (isFront) => {
      const rimSize = isFront ? vehicleDetails[0]?.frontRimSize : vehicleDetails[0]?.rearRimSize;
      if (rimSize) {
        const isEmpty = isEmptyOrNull(rimSize);
        const isZero = rimSize === '0';
        const isOtherRimEmpty = isFront ? !vehicleDetails[0]?.rearRimSize : !vehicleDetails[0]?.frontRimSize;
        return isEmpty ? (isZero && isOtherRimEmpty) ? (isFront ? vehicleDetails[0]?.frontRimSize : vehicleDetails[0]?.rearRimSize) : (isFront ? vehicleDetails[0]?.rearRimSize : vehicleDetails[0]?.frontRimSize) : rimSize;
      }
      return constants.EMPTY_STRING;
    };

    const fetchVehicleDetails = (front, rear) => {
      if (front) {
        if (isEmptyOrNull(front)) {
          return rear;
        }
        return front;
      } if (rear) {
        if (isEmptyOrNull(rear)) {
          return front;
        }
        return rear;
      }
    };
    if (isAssemblyPropertyDiff() || !isAssemblyPropertySingleIndexDiff) {
      const isFrontAxle = vehicleDetails[0].axleType === 'F';
      return {
        frontTirePressure: !isGeneric ? isFrontAxle ? fetchVehicleDetails(vehicleDetails[0]?.psi, vehicleDetails[1]?.psi) : fetchVehicleDetails(vehicleDetails[1]?.psi, vehicleDetails[0]?.psi) : fetchVehicleDetails(vehicleDetails[0]?.frontTirePressure, vehicleDetails[0]?.rearTirePressure),
        rearTirePressure: !isGeneric ? isFrontAxle ? fetchVehicleDetails(vehicleDetails[1]?.psi, vehicleDetails[0]?.psi) : fetchVehicleDetails(vehicleDetails[0]?.psi, vehicleDetails[1]?.psi) : fetchVehicleDetails(vehicleDetails[0]?.rearTirePressure, vehicleDetails[0]?.frontTirePressure),
        frontWheelWidth: !isGeneric ? isFrontAxle ? fetchVehicleDetails(vehicleDetails[0]?.wheelRimWidth, vehicleDetails[1]?.wheelRimWidth) : fetchVehicleDetails(vehicleDetails[1]?.wheelRimWidth, vehicleDetails[0]?.wheelRimWidth) : getGenericVehWheelWidth(true),
        rearWheelWidth: !isGeneric ? isFrontAxle ? fetchVehicleDetails(vehicleDetails[1]?.wheelRimWidth, vehicleDetails[0]?.wheelRimWidth) : fetchVehicleDetails(vehicleDetails[0]?.wheelRimWidth, vehicleDetails[1]?.wheelRimWidth) : getGenericVehWheelWidth(false),
        frontOffset: !isGeneric ? isFrontAxle ? fetchVehicleDetails(vehicleDetails[0]?.offset, vehicleDetails[1]?.offset) : fetchVehicleDetails(vehicleDetails[1]?.offset, vehicleDetails[0]?.offset) : constants.NOT_APPLICABLE,
        rearOffset: !isGeneric ? isFrontAxle ? fetchVehicleDetails(vehicleDetails[1]?.offset, vehicleDetails[0]?.offset) : fetchVehicleDetails(vehicleDetails[0]?.offset, vehicleDetails[1]?.offset) : constants.NOT_APPLICABLE,
        boltPattern: vehicleDetails[0]?.boltPattern,
        lugNutSize: vehicleDetails[0]?.lugNutSize,
        torque: vehicleDetails[0]?.torque,
        hubBore: vehicleDetails[0]?.hubBore,
        // the index parameter is to access the corresponding element from the vehicleDetails array based on the isFrontAxle flag being true or false
        construct: vehicleDetails?.map((c, index) => (isFrontAxle ? c.construct : vehicleDetails[1 - index]?.construct)),
        loadIndex: vehicleDetails?.map((c, index) => (isFrontAxle ? c.loadIndex : vehicleDetails[1 - index]?.loadIndex)),
        speedRating: vehicleDetails?.map((c, index) => (isFrontAxle ? c.speedRating : vehicleDetails[1 - index]?.speedRating)),
        tireConstruction: vehicleDetails?.map((c) => c.tireConstruction),
        isBothWheelsAreSame: false
      };
    }
    return vehicleDetails ? {
      frontTirePressure: fetchVehicleDetails(vehicleDetails[0]?.frontTirePressure, vehicleDetails[0]?.rearTirePressure),
      rearTirePressure: fetchVehicleDetails(vehicleDetails[0]?.rearTirePressure, vehicleDetails[0]?.frontTirePressure),
      frontWheelWidth: getGenericVehWheelWidth(true),
      rearWheelWidth: getGenericVehWheelWidth(false),
      boltPattern: vehicleDetails[0]?.boltPattern,
      lugNutSize: vehicleDetails[0]?.lugNutSize,
      torque: vehicleDetails[0]?.torque,
      hubBore: vehicleDetails[0]?.hubBore,
      wheelWidth: vehicleDetails[0]?.wheelRimWidth,
      wheelOffset: vehicleDetails[0]?.offset,
      construct: vehicleDetails?.map((c) => c.construct),
      loadIndex: vehicleDetails?.map((c) => c.loadIndex),
      speedRating: vehicleDetails?.map((c) => c.speedRating),
      tireConstruction: vehicleDetails?.map((c) => c.tireConstruction),
      isBothWheelsAreSame: true
    } : null;
  };
  const [assemblyDetails, setAssemblyDetails] = useState(selectedVehicle?.tireDetails?.vehicleDetails && loadAssemblyDetails(selectedVehicle?.tireDetails?.vehicleDetails));
  const [selectedAssembly, setSelectedAssembly] = useState(selectedVehicle?.vehicleDetails?.assemblyCollection?.filter(ac => ac.assemblyItem?.currentAssembly)[0]?.assemblyItem || selectedVehicle?.vehicleDetails?.assemblyCollection?.[0]?.assemblyItem);


  // Difference checker for VTV
  useEffect(() => {
    if (inspectionFields && selectedVehicle) {
      // Need to refactor this, seems to be too convuluded to check 2 arrays of all filled or all left empty
      const acceptableTreads = validTreadFields(inspectionFields.tireCollection);
      const initialInspectionNoAge = JSON.parse(JSON.stringify(initialInspection));
      // TO DO: Better deep copy setter for both, need to disregard tireAge acording to business. Based on DOT# always
      initialInspectionNoAge?.tireCollection?.forEach(tire =>  { tire.tireItem.tireAge = constants.EMPTY_STRING; });
      const inspectionFieldsNoAge = JSON.parse(JSON.stringify(inspectionFields));
      inspectionFieldsNoAge?.tireCollection?.forEach(tire =>  { tire.tireItem.tireAge = constants.EMPTY_STRING; });
      if (!objectsAreEqual(initialInspectionNoAge, inspectionFieldsNoAge) && acceptableTreads) {
        setVtvCanSave(inspectionFields);
      } else {
        setVtvCanSave(false);
      }
    }
  }, [inspectionFields]);

  useEffect(() => {
    setSelectedAssembly(selectedVehicle?.vehicleDetails?.assemblyCollection?.filter(ac => ac.assemblyItem.currentAssembly)[0]?.assemblyItem || selectedVehicle?.vehicleDetails?.assemblyCollection?.[0]?.assemblyItem);
    setIsStaggered(selectedVehicle?.vehicleDetails?.isStaggered);
    setIsOE((selectedVehicle?.vehicleDetails?.assemblyCollection?.filter(ac => ac.assemblyItem.currentAssembly)[0]?.assemblyItem?.assemblyType || selectedVehicle?.vehicleDetails?.assemblyCollection?.[0]?.assemblyItem?.assemblyType) === constants.VEHICLE_OE_ASSEMBLY_TYPE);
    setIsAfterMarket((selectedVehicle?.vehicleDetails?.assemblyCollection?.filter(ac => ac.assemblyItem.currentAssembly)[0]?.assemblyItem?.assemblyType || selectedVehicle?.vehicleDetails?.assemblyCollection?.[0]?.assemblyItem?.assemblyType) === constants.VEHICLE_ALT_ASSEMBLY_TYPE);
    setAssemblyDetails(loadAssemblyDetails(selectedVehicle?.tireDetails?.vehicleTireData));
  }, [selectedVehicle]);
  // Removed use effect, the fetch by vehicleId is not in scope
  return (
    <VehicleContainer isVTVAircheck={isVTVAircheck}>
      {!isVTVAircheck && (
        <SelectedVehicleLabelWrapper>
          <SelectedVehicleLabel>
            <SelectedVehicleCheck />
            {constants.VEHICLE_SELECTED_VEHICLE_LABEL}
          </SelectedVehicleLabel>

          <SelectedVehicleEditLabel isHidden={isBlocked} isBlocked={isBlocked} onClick={isBlocked ? null : editVehicle}>
            {constants.VEHICLE_SELECTED_VEHICLE_EDIT_LABEL}
            <EditIcon />
          </SelectedVehicleEditLabel>
        </SelectedVehicleLabelWrapper>
      )}
      <VehicleWrapper
        isVTVAircheck={isVTVAircheck}
        style={{ minWidth: '540px', maxWidth: '540px' }}
      >
        <Vehicle
          vehicle={selectedVehicle.vehicleDetails}
          isHidden={isHidden}
          tireDetails={selectedVehicle?.tireDetails}
          isDetailView isVTVAircheck={isVTVAircheck}
          editVehicle={editVehicle}
          inspectionDetails={inspectionFields}
          setInspectionDetails={handleInput}
          assemblyDetails={assemblyDetails}
        />
        <VehicleDetailsContainer>
          {!isVTVAircheck && (
            <>
              <VehicleInfoWrapper style={{ display: 'relative' }}>
                {
                  !isStaggered
                    ? (
                      <>
                        {(selectedAssembly && !isGeneric) ? (
                          <OEBadgeWrapper>
                            {
                              !isAfterMarket
                                ? <OEBadge name={selectedAssembly.assemblyType !== constants.VEHICLE_WTR_ASSEMBLY_TYPE ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}>{selectedAssembly.assemblyType !== constants.VEHICLE_WTR_ASSEMBLY_TYPE ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}</OEBadge>
                                :  selectedAssembly.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE
                                  ? <OEBadge name={constants.VEHICLE_WTR_LABEL}>{constants.VEHICLE_WTR_LABEL}</OEBadge>
                                  : <OEBadge name={constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL}>{selectedAssembly.sizeQualifier}</OEBadge>
                            }
                          </OEBadgeWrapper>
                        ) : null}
                        <FactoryTireSizeWrapper $isWinter={selectedAssembly?.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE}
                          >
                          <FactoryTireSize> {
                          selectedAssembly ?
                            `${assemblyDetails?.tireConstruction[0] === constants.ASSEMBLY_TIRECONSTRUCTION ? '' : (assemblyDetails?.tireConstruction[0] || constants.EMPTY_STRING)}${selectedAssembly?.frontTireWidth}/${selectedAssembly?.frontTireAspectRatio} R${selectedAssembly?.frontTireDiameter} ${assemblyDetails?.loadIndex[0] || constants.EMPTY_STRING}${assemblyDetails?.speedRating[0] || constants.EMPTY_STRING} ${assemblyDetails?.construct[0] || constants.EMPTY_STRING}` || constants.NOT_APPLICABLE : constants.NOT_APPLICABLE
}
                          </FactoryTireSize>
                          <FactoryTireSizeLabel>{!isGeneric ? isAfterMarket ? constants.VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_LABEL : constants.VEHICLE_FACTORY_TIRE_SIZE_LABEL : constants.VEHICLE_TIRE_WHEEL_LABEL}</FactoryTireSizeLabel>
                        </FactoryTireSizeWrapper>
                      </>
                    )
                    : (
                      <>{!isGeneric
                        && (
                        <OEBadgeWrapper>
                          {
                          !isAfterMarket
                            ? <OEBadge isStaggered name={selectedAssembly?.assemblyType !== constants.VEHICLE_WTR_ASSEMBLY_TYPE ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}>{selectedAssembly.assemblyType !== constants.VEHICLE_WTR_ASSEMBLY_TYPE ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}</OEBadge>
                            :                          selectedAssembly?.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE
                              ? <OEBadge isStaggered name={constants.VEHICLE_WTR_LABEL}>{constants.VEHICLE_WTR_LABEL}</OEBadge>
                              : <OEBadge isStaggered name={constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL}>{selectedAssembly.sizeQualifier}</OEBadge>
                        }
                        </OEBadgeWrapper>
                        )}
                        <FactoryTireSizeWrapper>
                          <FactoryTireSize isStaggered>{selectedAssembly ? `${assemblyDetails?.tireConstruction[0] === constants.ASSEMBLY_TIRECONSTRUCTION ? '' : (assemblyDetails?.tireConstruction[0] || constants.EMPTY_STRING)}${selectedAssembly.frontTireWidth}/${selectedAssembly.frontTireAspectRatio} R${selectedAssembly.frontTireDiameter} ${assemblyDetails?.loadIndex[0] || constants.EMPTY_STRING}${assemblyDetails?.speedRating[0] || constants.EMPTY_STRING} ${assemblyDetails?.construct[0] || constants.EMPTY_STRING}` || selectedAssembly?.factoryTireSize || constants.NOT_APPLICABLE : constants.NOT_APPLICABLE}</FactoryTireSize>
                          {!isGeneric ? <FactoryTireSizeLabel>{isAfterMarket ? constants.VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_FRONT_LABEL : constants.VEHICLE_FACTORY_TIRE_SIZE_FRONT_LABEL}</FactoryTireSizeLabel>
                            : <FactoryTireSizeLabel>{constants.VEHICLE_TIRE_WHEEL_LABEL}</FactoryTireSizeLabel>}
                        </FactoryTireSizeWrapper>
                        { !isGeneric && (
                          <OEBadgeWrapper isRear>
                            {
                          !isAfterMarket
                            ? <OEBadge isStaggered name={selectedAssembly.assemblyType !== constants.VEHICLE_WTR_ASSEMBLY_TYPE ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}>{selectedAssembly.assemblyType !== constants.VEHICLE_WTR_ASSEMBLY_TYPE ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}</OEBadge>
                            :                          selectedAssembly.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE
                              ? <OEBadge isStaggered name={constants.VEHICLE_WTR_LABEL}>{constants.VEHICLE_WTR_LABEL}</OEBadge>
                              : <OEBadge isStaggered name={constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL}>{selectedAssembly.sizeQualifier}</OEBadge>
                        }
                          </OEBadgeWrapper>
                        )}
                        <FactoryTireSizeWrapper>
                        <FactoryTireSize isStaggered> {selectedAssembly ? `${assemblyDetails?.tireConstruction[1] === constants.ASSEMBLY_TIRECONSTRUCTION ? '' : (assemblyDetails?.tireConstruction[1] || constants.EMPTY_STRING)}${selectedAssembly.rearTireWidth || constants.EMPTY_STRING}/${selectedAssembly.rearTireAspectRatio || constants.EMPTY_STRING} R${selectedAssembly.rearTireDiameter || constants.EMPTY_STRING} ${assemblyDetails?.loadIndex[1] || constants.EMPTY_STRING}${assemblyDetails?.speedRating[1] || constants.EMPTY_STRING} ${assemblyDetails?.construct[1] || constants.EMPTY_STRING}` || selectedAssembly?.rearFactoryTireSize || constants.NOT_APPLICABLE : constants.NOT_APPLICABLE}</FactoryTireSize>
                        {!isGeneric ? <FactoryTireSizeLabel>{isAfterMarket ? constants.VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_REAR_LABEL : constants.VEHICLE_FACTORY_TIRE_SIZE_REAR_LABEL}</FactoryTireSizeLabel>
                            : <FactoryTireSizeLabel>{constants.VEHICLE_TIRE_WHEEL_LABEL}</FactoryTireSizeLabel>}
                        </FactoryTireSizeWrapper>
                      </>
                    )
                }

              </VehicleInfoWrapper>
            </>
          )}
          {!isVTVAircheck && (
            <>
              <VehicleInfoWrapper>
                <TireDetailsWrapper>
                  <TireDetailsLabel>&nbsp;</TireDetailsLabel>
                  <TireDetailsLabel>
                    {constants.VEHICLE_TIRE_PRESSURE_LABEL}:
                  </TireDetailsLabel>
                  <TireDetailsLabel>
                    {constants.VEHICLE_WHEEL_WIDTH_LABEL}:
                  </TireDetailsLabel>
                  <TireDetailsLabel>
                    {constants.VEHICLE_OFFSET_LABEL}:
                  </TireDetailsLabel>
                </TireDetailsWrapper>
              </VehicleInfoWrapper>
              {((isAssemblyPropertyDiff(selectedVehicle?.tireDetails?.vehicleDetails)) || (isAssemblyPropertySingleIndexDiff(selectedVehicle?.tireDetails?.vehicleDetails))) ? (
                <>
                  <VehicleInfoWrapper>
                    <TireDetailsWrapper>
                      <TireDetailsLabel>
                        {constants.FRONT}
                      </TireDetailsLabel>
                      <TireDetailsLabel>
                        <TireDetailsValue>
                          {assemblyDetails?.frontTirePressure || constants.EMPTY_STRING} {constants.PSI}
                        </TireDetailsValue>
                      </TireDetailsLabel>
                      <TireDetailsLabel>
                        <TireDetailsValue>
                          {assemblyDetails?.frontWheelWidth ? (assemblyDetails?.frontWheelWidth || constants.EMPTY_STRING) + constants.INCHES_ABBR : constants.NOT_APPLICABLE }
                        </TireDetailsValue>
                      </TireDetailsLabel>
                      <TireDetailsLabel>
                        <TireDetailsValue>
                          {!isGeneric ?  (assemblyDetails?.frontOffset || constants.EMPTY_STRING) + constants.MILLIMETERS : constants.NOT_APPLICABLE }
                        </TireDetailsValue>
                      </TireDetailsLabel>
                    </TireDetailsWrapper>
                  </VehicleInfoWrapper>
                  <VehicleInfoWrapper>
                    <TireDetailsWrapper>
                      <TireDetailsLabel>
                        {constants.REAR}
                      </TireDetailsLabel>
                      <TireDetailsLabel>
                        <TireDetailsValue>
                          {assemblyDetails?.rearTirePressure || constants.EMPTY_STRING} {constants.PSI}
                        </TireDetailsValue>
                      </TireDetailsLabel>
                      <TireDetailsLabel>
                        <TireDetailsValue>
                          {!isGeneric ?  (assemblyDetails?.rearWheelWidth || constants.EMPTY_STRING) + constants.INCHES_ABBR : (assemblyDetails?.rearWheelWidth ? assemblyDetails.rearWheelWidth  + constants.INCHES_ABBR : constants.NOT_APPLICABLE) }
                        </TireDetailsValue>
                      </TireDetailsLabel>
                      <TireDetailsLabel>
                        <TireDetailsValue>
                          {!isGeneric ?  (assemblyDetails?.rearOffset || constants.EMPTY_STRING) + constants.MILLIMETERS : constants.NOT_APPLICABLE }
                        </TireDetailsValue>
                      </TireDetailsLabel>
                    </TireDetailsWrapper>
                  </VehicleInfoWrapper>
                </>
              )
                :   (
                  <VehicleInfoWrapper>
                    <TireDetailsWrapper>
                      <TireDetailsLabel>
                        {constants.FRONTREAR}
                      </TireDetailsLabel>
                      <TireDetailsLabel>
                        <TireDetailsValue>
                          {assemblyDetails?.frontTirePressure || constants.EMPTY_STRING} {constants.PSI}
                        </TireDetailsValue>
                      </TireDetailsLabel>
                      <TireDetailsLabel>
                        <TireDetailsValue>
                          {!isGeneric ?  (assemblyDetails?.wheelWidth ? (assemblyDetails?.wheelWidth || constants.EMPTY_STRING) + constants.INCHES_ABBR : constants.NOT_APPLICABLE) : (assemblyDetails?.frontWheelWidth ? (assemblyDetails.frontWheelWidth || constants.EMPTY_STRING) + constants.INCHES_ABBR : constants.NOT_APPLICABLE) }
                        </TireDetailsValue>
                      </TireDetailsLabel>
                      <TireDetailsLabel>
                        <TireDetailsValue>
                          {!isGeneric ? (assemblyDetails?.wheelOffset || constants.EMPTY_STRING) + constants.MILLIMETERS  : constants.NOT_APPLICABLE }
                        </TireDetailsValue>
                      </TireDetailsLabel>
                    </TireDetailsWrapper>
                  </VehicleInfoWrapper>
                )}

              <VehicleInfoWrapper>
                <LargeVerticalBar />
                <TireDetailsWrapper>
                  <TireDetailsLabel>
                    {constants.VEHICLE_TORQUE_LABEL}:
                  </TireDetailsLabel>
                  <TireDetailsLabel>
                    {constants.VEHICLE_BOLT_PATTERN_LABEL}:
                  </TireDetailsLabel>
                  <TireDetailsLabel>
                    {constants.VEHICLE_LUG_NUT_SIZE_LABEL}:
                  </TireDetailsLabel>
                  <TireDetailsLabel>
                    {constants.VEHICLE_HUB_BORE_LABEL}:
                  </TireDetailsLabel>
                </TireDetailsWrapper>
              </VehicleInfoWrapper>
              <VehicleInfoWrapper>
                <TireDetailsWrapper>
                  <TireDetailsLabel><TireDetailsValue> {assemblyDetails?.torque || assemblyDetails?.wheelTorque || constants.EMPTY_STRING} {constants.FT_LBS} </TireDetailsValue>
                  </TireDetailsLabel>
                  <TireDetailsLabel><TireDetailsValue> {assemblyDetails?.boltPattern || assemblyDetails?.wheelBoltPattern || constants.EMPTY_STRING} {constants.MILLIMETERS}</TireDetailsValue>
                  </TireDetailsLabel>
                  <TireDetailsLabel><TireDetailsValue> {assemblyDetails?.lugNutSize || assemblyDetails?.wheelLugNutSize || constants.EMPTY_STRING || constants.NOT_APPLICABLE}</TireDetailsValue>
                  </TireDetailsLabel>
                  <TireDetailsLabel><TireDetailsValue> {assemblyDetails?.hubBore || assemblyDetails?.wheelHubBore || constants.EMPTY_STRING || constants.NOT_APPLICABLE}</TireDetailsValue>
                  </TireDetailsLabel>
                </TireDetailsWrapper>
              </VehicleInfoWrapper>
            </>
          )}
        </VehicleDetailsContainer>
      </VehicleWrapper>
      {isVTVAircheck && (
        <>
          <VehicleVBar />
          <VehicleWrapper style={{ maxWidth: '281px', margin: '0' }}>
            <TireInformationDetails setInspectionDetails={handleInput} inspectionDetails={inspectionFields} isDually={isDually}/>
          </VehicleWrapper>
          <VehicleVBar />
          <VehicleWrapper style={{ minWidth: '778px', margin: '0' }}>
            <VehicleDiagram
              selectedVehicle={selectedVehicle}
              isTreadDetailsAvailable
              treadDetails={inspectionFields}
              setTreadDetails={handleInput}
            />
          </VehicleWrapper>
        </>
      )}
    </VehicleContainer>
  );
};
export default VehicleDetails;
