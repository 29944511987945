import {
  useState, useEffect, useContext, useRef
} from 'react';
import { HiSearch } from 'react-icons/hi';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import {
  AiOutlineMinus, AiOutlinePlus, AiOutlineFilter, AiFillCloseCircle
} from 'react-icons/ai';
import TableBody from './TableBody';
import { theme } from '../../helpers/GlobalStyles';
import SearchInput from '../Input/SearchInput';
import * as constants from '../../constants/Constants';
import { CVMContext } from '../../components/CVM/CVM';
import { checkForEmptyObject, isNullEmptyUndefined } from '../../helper/validationHelper';
import { tableColumnSorting } from '../../helper/tableColumnSorting';
import searchFilter from '../../helper/searchFilter';
import styled from '../../helpers/esm-styled-components';
import CalendarDatePicker from '../Calendar/Calendar';
import { defaultInspectionQuery } from '../../helper/baseDataHelper';
import { deepObjectCopy } from '../../helper/CustomerHelper';

const TableSearchContainer = styled.div``;

const SearchContainer = styled.div`
  top: 0;
  background-color: ${theme.colors.white};
  position: sticky;
  height: 100px;
  z-index: 5;
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  gap:1rem;
  padding-left: 1rem;
`;

const TableSearchInput = styled(SearchInput)`
   width: 16rem !important;
`;

const ClearFilters = styled.div`
  display: flex;
  margin-left: 44rem;
  align-items: center;
  cursor: ${({ filterActive }) => (filterActive ? 'pointer' : 'not-allowed')};;
  color: ${({ filterActive }) => (filterActive ? theme.colors.red : theme.colors.mediumGray)};
 `;

const FilterIcon = styled.img`
  margin-left: 2rem;
  filter: invert(19%) sepia(98%) saturate(1942%) hue-rotate(208deg) brightness(95%) contrast(103%);
  cursor: pointer;
`;
const StyledHiSearch = styled(HiSearch)`
  font-size: ${theme.fontSize.large};
  margin-top:2.5%;
  margin-left: -7%;
  pointer-events: none;
`;

const ResultsTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  border-spacing: 0px;
  background-color: #fff;
  overflow: hidden;
  height:auto;
  ${({ tableType }) => (tableType === constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE && 'max-height: 330px;')};
`;

const AiFillCloseCircleIcon = styled(AiFillCloseCircle)`
 margin-right: 5px;
 color: ${({ filterActive }) => (filterActive ? theme.colors.red : theme.colors.mediumGray)};
`;

const FilterText = styled.p`
  margin-left: 5px;
  color: ${theme.colors.blue};
  font-weight: ${theme.fontFamily.bold};
  font-size: ${theme.fontSize.large};
  cursor: pointer;
`;

const FormContainer = styled.div`
  position: absolute;
  z-index: 10;
  left:35rem;
  top:5rem;
  border: 1px solid grey;
  box-shadow: 0 0 5px grey;
  padding-right: 70px;
  background-color: white;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const Form = styled.form`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

const RadioLabel = styled.label`
  margin-bottom: 10px;
`;

const RadioTitle = styled.label`
`;
const TableHeaders = styled.div`
  margin-top:${({ tableType }) => (tableType === constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE && '35px')};
  font-size: ${theme.fontSize.modalTitle};
  font-family: ${theme.fontFamily.heavy};
  color: ${theme.colors.obsidian};
  padding: 0 62px 11px 0px;
  top: 80px;
  z-index: ${({ isExpandedView }) => (isExpandedView ? '4' : '5')}; // Set z-index to -1 when expanded
  text-align: left;
  position: sticky;
  display: flex;
  align-items: center;
  white-space: nowrap;
  ${({ isExpandedView }) => isExpandedView &&
  `color:${theme.colors.editBlue}; cursor:pointer; font-size:${theme.fontSize.large}; font-family:${theme.fontFamily.bold}`};
  //Setting for Customer search change based on tableType
  ${({ tableType, index, previousOrigin }) => {
    if (tableType === constants.CUSTOMER_RESULTS_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 300px 10px 20px';
        case 2:
          return 'padding-right: 265px';
        case 3:
          return 'padding-right: 45px';
        case 4:
          return 'padding: 0 30px 10px 20px;';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.VEHICLES_RESULTS_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 220px 11px 50px';
        case 1:
          return 'padding-right: 138px';
        case 2:
          return 'padding-right: 110px';
        case 3:
          return 'padding-right: 25px';
        case 4:
          return 'padding-right: 40px';
        case 5:
          return 'padding-right: 50px';
        case 6:
          return 'padding-right: 15px';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.VTV_RESULTS_TABLE || tableType === constants.AIRCHECK_RESULTS_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 155px 10px 20px';
        case 1:
          return 'padding-right: 385px';
        case 2:
          return 'padding-right: 45px';
        case 3:
          return 'padding-right: 45px';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.DEACTIVATED_VEHICLES_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 225px 11px 141px';
        case 1:
          return 'padding-right: 150px';
        case 2:
          return 'padding-right: 260px';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.ACTIVE_VEHICLES_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 329px 11px 80px;';
        case 1:
          return 'padding-right: 125px';
        case 2:
          return 'padding-right: 150px';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 329px 11px 80px;';
        case 1:
          return 'padding-right: 412px';
        default:
          return constants.EMPTY_STRING;
      }
    }
  }}
`;

const TableHeaderRow = styled.div`
  display: flex;
  top: 100px;
  background-color: ${theme.colors.white};
  position: static;
  border-bottom: 1px solid black;
`;
// Icons
export const DownSortIcon = styled(FaChevronDown)`
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${theme.colors.searchGray};
  margin-left: 9px;
  cursor: pointer;
`;
export const UpSortIcon = styled(FaChevronUp)`
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${theme.colors.searchGray};
  margin-left: 9px;
  cursor: pointer;
`;
const PlusHeaderIcon = styled(AiOutlinePlus)`
  position:relative;
`;
const MinusHeaderIcon = styled(AiOutlineMinus)`
  position:relative;
`;

const Table = (props) => {
  const {
    headers,
    detailHeaders,
    dataResults,
    hasSearch,
    tableType,
    scrollHandler,
  } = props;
  const {
    searchOrigin,
    setDVRSelected,
    setSearchQuery,
    filter,
    filters,
    selectedValue,
    selectedDate,
    filtersTurnedOn,
    setFilter,
    setFilters,
    setSelectedDate,
    setFiltersTurnedOn,
    setSelectedValue
  } = useContext(CVMContext);
  // Temporarily copying the object, for local mutations
  let data = [...dataResults];
  const isInspectionTable = tableType === constants.VTV_RESULTS_TABLE || tableType === constants.AIRCHECK_RESULTS_TABLE;
  const [tableSearchResults, setTableSearchResults] = useState(data);
  const [sortedDirection, setSortedDirection] = useState(true);
  const [tableHasSort, setTableHasSort] = useState((tableType === constants.DEACTIVATED_VEHICLES_TABLE
    || tableType === constants.ACTIVE_VEHICLES_TABLE
    || tableType === constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE
    || tableType === constants.VTV_RESULTS_TABLE
    || tableType === constants.AIRCHECK_RESULTS_TABLE));
  const isTableFilter = tableType == constants.CUSTOMER_RESULTS_TABLE || tableType == constants.VEHICLES_RESULTS_TABLE || isInspectionTable;
  const [expandedInfo, setExpandedInfo] = useState(false);
  const currentDate = new Date();
  const hasExpand = tableType === constants.CUSTOMER_RESULTS_TABLE || tableType === constants.VEHICLES_RESULTS_TABLE;
  const clearFilters = () => {
    if (isInspectionTable) handleDateChange(new Date());
    setFilters(0);
    setFiltersTurnedOn({ isRadioOn: false, isCalendarOn: false });
    setSelectedValue('both');
    setFilter(filter);
  };
  const sortingHandler = (filteredData) => {
    if (!tableHasSort) {
      return filteredData || data;
    }
    return tableColumnSorting(filteredData || data, () => {
      switch (tableType) {
        case constants.VTV_RESULTS_TABLE:
          return { dataType: constants.COLUMN_DATA_TYPE_TIME, columnPropName: 'vtvTimeCompleted' };
        case constants.AIRCHECK_RESULTS_TABLE:
          return { dataType: constants.COLUMN_DATA_TYPE_TIME, columnPropName: 'airCheckTimeCompleted' };
        case constants.ACTIVE_VEHICLES_TABLE:
        case constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE:
        case constants.DEACTIVATED_VEHICLES_TABLE:
          return {
            dataType: [constants.COLUMN_DATA_TYPE_NUMBER, constants.COLUMN_DATA_TYPE_STRING, constants.COLUMN_DATA_TYPE_STRING],
            columnPropName: ['year', 'make', 'model']
          };
        default:
          break;
      }
    }, sortedDirection);
  };
  const setSort = () => {
    setSortedDirection(!sortedDirection);
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const sort = () => (sortedDirection ? <DownSortIcon onClick={setSort} /> : <UpSortIcon onClick={setSort} />);
  const expand = () => (expandedInfo ? <MinusHeaderIcon/> : <PlusHeaderIcon/>);
  const expandAll = () => { setExpandedInfo(!expandedInfo); };
  const onSearch = (event) => {
    if (!expandedInfo && event.target.value.length > 0) {
      expandAll();
    }
    if (event.target.value.length > 0) {
      setDVRSelected(null);
    }
    if (event.target.value.length == 0) {
      setExpandedInfo(!expandedInfo);
    }
    setFilter(event.target.value.toLowerCase());
  };

  useEffect(() => {
   setFilter('');
  }, []);


  const radioOptions = [
    {
      id: 1, name: 'handle', value: 'both', label: 'Both'
    },
    {
      id: 2, name: 'handle2', value: 'B2B', label: 'B2B'
    },
    {
      id: 3, name: 'handle3', value: 'B2C', label: 'B2C'
    }
  ];

  const formRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setSelectedDate(new Date());
  }, [tableType]);

  useEffect(() => {
    // SET DATA HERE FOR REFLECT IN TABLE BODY
    if (isInspectionTable) {
      data = data?.map((c) => {
        // NOTE: Other workstreams are still using the lastName workaround for customer type, remove once thats fixed
        if (c.customer?.lastName === constants.BUSINESS_CUSTOMER_TYPE || c.customer?.customerType === constants.BUSINESS_CUSTOMER_TYPE) {
          c.customerType = constants.BUSINESS_CUSTOMER_TYPE;
        } else {
          c.customerType = constants.STANDARD_CUSTOMER_TYPE;
        }
        return c;
      });
    }
    if (selectedValue !== 'both') data = data.filter(c => c.customerType === selectedValue);

    if (isNullEmptyUndefined(filter)) {
      setTableSearchResults(sortingHandler());
      return;
    }
    setTableSearchResults(sortingHandler(searchFilter(searchOrigin, tableType, data, filter)));
  }, [filter, dataResults, selectedValue, sortedDirection]);

  /* Calendar Filter Logic */
  const handleDateChange = (date) => {
    if (date.getDate() !== currentDate.getDate() && !filtersTurnedOn.isCalendarOn) {
      setFilters(filters + 1);
      setFiltersTurnedOn({ ...filtersTurnedOn,  isCalendarOn: true });
    } else if (date.getDate() === currentDate.getDate() && filtersTurnedOn.isCalendarOn) {
      setFilters(filters - 1);
      setFiltersTurnedOn({ ...filtersTurnedOn,  isCalendarOn: false });
    }
    setSelectedDate(date);
    const query = defaultInspectionQuery(date);
    setSearchQuery(query);
  };
  const isDisabledDate = (date) => {
    /* Disable Sundays */
    if (date.getDay() === 0) {
      return true;
    }
    // Enable today and every day prior to today from the previous month to today
    const today = new Date();
    const previousMonth = new Date(today);
    previousMonth.setMonth(today.getMonth() - 1);
    return date > today || date < previousMonth;
  };

  /* Filter Logic */
  const typeFilter = () => {
    setIsExpanded(prevState => !prevState);
  };

  const handleRadioChange = (event) => {
    setIsExpanded(false);
    const radioSelection = event.target.value;
    setSelectedValue(event.target.value);
    if (!filtersTurnedOn.isRadioOn && radioSelection !== 'both') {
      setFilters(filters + 1);
      setFiltersTurnedOn({ ...filtersTurnedOn,  isRadioOn: true });
    } else if (filtersTurnedOn.isRadioOn && radioSelection === 'both' && filters) {
      setFilters(filters - 1);
      setFiltersTurnedOn({ ...filtersTurnedOn,  isRadioOn: false });
    }
  };

  return (
    <TableSearchContainer>
      {hasSearch && (
        <SearchContainer>
          <SearchWrapper>
            {isInspectionTable && (
              <CalendarDatePicker selectedDate={selectedDate} handleDateChange={handleDateChange} disabledDates={isDisabledDate} currentDate={currentDate}/>
            )}
            <TableSearchInput width={isInspectionTable ? '16rem;' : '32rem'} onSearch={(event) => onSearch(event)} />
            <StyledHiSearch isInspectionTable={isInspectionTable} />
            {isTableFilter && (
              <>
                <FilterIcon
                  onClick={typeFilter}
                  src="/GenericVehicleImages/filter_transparent.svg"
                  alt="genxeric filter icon"
                />
                <FilterText onClick={typeFilter}>
                  {constants.FILTER} ({filters})
                </FilterText>
                <FormContainer isVisible={isExpanded} ref={formRef}>
                  <Form>
                    <p>{constants.FILTER_BY}</p>
                    {radioOptions.map((option) => (
                      <RadioLabel key={option.id} htmlFor={option.value}>
                        <input
                          type="radio"
                          name={option.name}
                          value={option.value}
                          checked={selectedValue === option.value}
                          onChange={handleRadioChange}
                        />
                        <RadioTitle
                          onClick={() => handleRadioChange({
                            target: { value: option.value },
                          })}
                        >
                          {option.label}
                        </RadioTitle>
                      </RadioLabel>
                    ))}
                  </Form>
                </FormContainer>
              </>
            )}
          </SearchWrapper>
          {isTableFilter && (
            <ClearFilters onClick={filters > 0 ? clearFilters : null} filterActive={filters > 0}>
              <AiFillCloseCircleIcon filterActive={filters > 0} />
              {constants.CLEAR_FILTERS}
            </ClearFilters>
          )}
        </SearchContainer>
      )}
      <TableHeaderRow isExpandedView={isExpanded}>
        {headers.map((c, index) => {
          return (
            <TableHeaders tableType={tableType} index={index} key={index}
              >
              {(c === constants.CUSTOMER_DEACTIVATED_VEHICLES[0]
              || c === constants.CUSTOMER_ADDITIONAL_ACTIVE_VEHICLES[0])
              && `${tableSearchResults?.length} `}
              {c}
              {(c === constants.CUSTOMER_DEACTIVATED_VEHICLES[0]
              || c === constants.CUSTOMER_ADDITIONAL_ACTIVE_VEHICLES[0]
              || c === constants.VTV_SEARCH_RESULTS_HEADERS[4]
              || c === constants.AIRCHECK_SEARCH_RESULTS_HEADERS[4])
              && sort()}
            </TableHeaders>
          );
        })}
        {hasExpand && tableSearchResults.length > 1 && (
          <TableHeaders isExpandedView onClick={expandAll}>
            {expandedInfo ? constants.COLLAPSE_ALL : constants.EXPAND_ALL}
            {hasExpand && expand()}
          </TableHeaders>
        )}
      </TableHeaderRow>
      <ResultsTable tableType={tableType}>
        <TableBody
          data={tableSearchResults}
          filter={filter}
          selectedValue={selectedValue}
          detailHeaders={detailHeaders}
          tableType={tableType}
          isExpandedView={expandedInfo}
          hasExpand={hasExpand}
          scrollHandler={scrollHandler}
        />
      </ResultsTable>
    </TableSearchContainer>
  );
};
export default Table;
