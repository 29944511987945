import API from 'dt-cvm-api';
import debug from 'debug';
import env from '@beam-australia/react-env';
import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import { HiSearch } from 'react-icons/hi';
import Select from 'react-select';
import styled from '../../../helpers/esm-styled-components';
import { theme } from '../../../helpers/GlobalStyles';
import DetailSection from '../DetailSection';
import { CVMContext } from '../../CVM/CVM';
import * as constants from '../../../constants/Constants';
import Vehicle from '../Vehicle/Vehicle';
import VehicleDetails from '../Vehicle/VehicleDetails';
import SearchInput from '../../../UIComponents/Input/SearchInput';
import SearchButton from '../../../UIComponents/Button/SearchButton';
import Table from '../../../UIComponents/Table/Table';
import Message from '../../../UIComponents/Error/Message';
import { APIRouter } from '../../../Data/APIRouter';


import {
  filterCriteriaOptions
} from '../../../helper/baseDataHelper';

import {
  isVehicleActive,
  splitActiveInactiveVehicles,
} from '../../../UIComponents/Modals/VehicleModals/StandardVehicle/VehicleHelper';

const Wrapper = styled.div`
  grid-template-rows: 3em minmax(0, 1fr);
  grid-template-areas: "header" "main";
  display: grid;
  flex-grow: 1;
  height: 100%;
`;
const VehicleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`;

const SearchContainer = styled.div`
  grid-area: header;
  /*position: sticky;*/
  top: 0%;
  /*left: 50%;*/
  /*margin-top: -22px;*/
  background-color: ${theme.colors.white};
  /*position: sticky;*/
  /*height: 20px;*/
  width: 50%;
  z-index: 150;
`;

const SearchWrapper = styled.div`
  padding-top: 10px;
  margin-left: 20px;
  width: fit-content;
  position: sticky;
  z-index: 3;
  display: flex;
  height: 1em;

  & input {
    width: 100%;
    font-size: 120%;
  }

  & [class*="menu"] {
    margin-top: 2em;
  }

  & [class*="MenuList"] {
    z-index: 11000;
  }

  & [class*="SearchButton"] {
    margin-left: 10px;
    height: 2.5em;
  }
`;
const ViewAllVehiclesButtonWrapper = styled.div`
  margin:  ${({ isAllVehiclesView }) => (isAllVehiclesView ? '50px 0;' : '30px; 0 0 0;')};
  font-weight: ${theme.fontWeight.semiBold};
  font-size: ${theme.fontSize.large};
  text-align: center;
`;
const ViewAllVehiclesButton = styled.a`
  color: ${theme.colors.blue};
  text-transform: uppercase;
  border-bottom: 1px solid ${theme.colors.darkGray};
  padding-bottom: 8px;
  font-family: ${theme.fontFamily.semiBold};
  cursor: pointer;
`;
const ArrowIcon = styled.span`
  color: ${({ theme }) => theme.colors.darkGray};
  margin-right: 0.2em;
  vertical-align: middle;
`;
const HorizontalDivider = styled.hr`
  border: 1px solid ${theme.colors.mediumGray};
  margin-right: 25px;
`;
const TableWrapper = styled.div`
  margin: 0px 50px 0 50px;
  overflow-y: auto;
  height: ${({ isInGK }) => (isInGK ? '550px' : '590px')};
`;
const UpIcon = styled(IoChevronUpOutline)`
  color: ${theme.colors.blue};
  font-size: ${theme.fontSize.editLabel};
`;
const DownIcon = styled(IoChevronDownOutline)`
  color: ${theme.colors.blue};
  font-size: ${theme.fontSize.editLabel};
`;

const SearchLabel = styled.div`
  padding-top: 0.6em;
`;

const SearchCriteriaInput = styled.input`
  font-size: 100%;
  padding-left: 0.5em;
  height: 38px;
  border: solid 1px lightgray;
  border-left: none;
  margin-top: 0;
`;

export const ActiveVehiclesDetailSection = styled(DetailSection)`

  & {
    grid-area: main;

    display: grid;

    grid-template-areas: "sectionheader" "sectioncontent";
    grid-template-rows: 1em minmax(0, 1fr);
  }

  & [class*="DetailVBar"] {
    visibility: hidden;
  }

  & [class*="DetailWrapper"] {
    margin-top: -20px;
    width: 100%;
  }

  & [class*="DetailTitleWrapper"] {
    grid-area: sectionheader;

    position: relative;

    margin-top: -3em;
    margin-right: 20px;
    justify-content: flex-end;

    z-index: 100;
  }

  & [class*="TableWrapper"] {
    /*margin-top: 60px;*/

    & [class*="TableHeaderRow"] {
      z-index: 0;
    }
  }

  & [class*="DetailSection__DetailSectionContent"] {
    padding: 0;

    grid-area: sectioncontent;

    display: grid;
    grid-template-areas: "details" "list";
    grid-template-rows: 3.5em minmax(0, 1fr);

  }

  &[data-expanded="true"] [class*="DetailSection__DetailSectionContent"] {
    grid-template-rows: 16em minmax(0, 1fr);
  }

  & [class*="VehicleWrapper"] {
    grid-area: details;
  }

  & [class*="SelectedVehicleLabelWrapper"] {
    width: 100%;
    text-align: right;
    z-index: 150;
  }

  & [class*="TableWrapper"] {
    grid-area: list;

    margin: 0;
  }

  & hr {
    display: none;
  }

  & [class*="DetailSectionTitle"] {
    font-family: inherit;
    font-weight: normal;

    position: absolute;

    top: 4em;
    right: -20px;
    left: -20px;

    line-height: 2em;
    padding-left: 20px;

    font-size: 100%;

    border-top: solid 1px gray;
    border-bottom: solid 1px gray;
  }

  & [class*="SelectedVehicleLabelWrapper"] {
    margin-top: 1.75em;
  }

  & [class*="SelectedVehicleLabel"] {
    position: relative;
    top: -12px;
  }

  & [class*="SelectedVehicleEditLabel"] {
    position: relative;
    top: -12px;
  }

  & [class*="TableSearchContainer"] {
    height: 100%;

    display: grid;
    grid-template-areas: "dataheader" "datacontent";
    grid-template-rows: 2em minmax(0, 1fr);
  }

  & [class*="TableHeaderRow"] {
    grid-area: dataheader;
  }

  & [class*="TableBody__TableContainer"] {
    max-height: none;
  }

  & [class*="ResultsTable"] {
    grid-area: datacontent;
  }
`;

const logger = debug('api');

const CustomerVehicles = props => {
  const {
    customerInfo,
    deactivatedVehicles,
    setDeactivatedVehicles,
    setSelectedVehicle,
    setLoader,
    setVehicles,
    vehicles,
  } = useContext(CVMContext);

  const {
    titleButtonsArr, isBlocked, selectVehicle, editVehicle, selectedVehicle, showDeactivatedVehicles, applyCustomerVehicleError,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [tableSearchResults, setTableSearchResults] = useState(vehicles || []);
  const activeVehicles = vehicles || [];
  const inactiveVehicles = deactivatedVehicles || [];
  const [numberOfVehicles, setNumberOfVehicles] = useState(activeVehicles.length);
  const [searchTitle, setSearchTitle] = useState('');

  // Dynamically adjusts the need for a scrollbar
  const ref = useRef();
  const [height, setHeight] = useState(0);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [selectedCriteria, setSelectedCriteria] = useState({ value: 'licensePlateNumber', label: 'Plate #' });
  const [searchInputValue, setSearchInputValue] = useState('');

  const [fullyLoaded, setFullyLoaded] = useState(false);

  // useEffect(() => { setHeight(ref.current?.clientHeight); }, [height, showDeactivatedVehicles]);

  useEffect(() => {
    setSearchTitle(`Showing ${numberOfVehicles} vehicles`);
  }, [numberOfVehicles]);

  useEffect(() => {
    setNumberOfVehicles(tableSearchResults?.length || 0);
  }, [tableSearchResults]);

  useEffect(() => {
    if (API.utils.isValid(selectedVehicle)) {
      document.body.querySelector('[class*="ActiveVehiclesDetailSection"]').setAttribute('data-expanded', 'true');
    } else {
      document.body.querySelector('[class*="ActiveVehiclesDetailSection"]').removeAttribute('data-expanded');
    }
  }, [selectedVehicle]);

  let filterCriteria,
    filterValue;

  const criteriaKeydown = async (reactEvent) => {

    const nativeEvt = reactEvent.nativeEvent;

    if (nativeEvt.keyCode === constants.ENTER_KEY_CODE || nativeEvt.keyCode === constants.TAB_KEY_CODE) {
      if (!searchInputValue) {
        setButtonDisabled(true);
        setTableSearchResults(vehicles || []);
        return;
      }

      await runSearchQuery();
    }
  };

  const handleScroll = async (event) => {
    if (fullyLoaded) {
      return;
    }

    const chunkSize = parseInt(env('API_MAX_VEHICLES'), 10) || 100;

    // The full set of active/inactive has to be assembled. If that
    // amount is exactly the size of 1 or more chunks there's likely at least
    // one more chunk out there to fetch.
    const allVehicles = [...activeVehicles, ...inactiveVehicles];
    if (API.utils.notEmpty(allVehicles)) {
      setFullyLoaded(allVehicles.length % chunkSize !== 0);
      if (fullyLoaded) {
        return;
      }
    }

    const TOLERANCE = 50;   //  how close to bottom before we fetch?
    const { target } = event;
    if (target.scrollHeight - target.offsetHeight - target.scrollTop < TOLERANCE) {

      const loadedChunks = Math.ceil(tableSearchResults.length / chunkSize);
      const firstRecord = chunkSize * loadedChunks + 1;

      let params;

      const searchVal = searchInputValue.trim();
      if (API.utils.isEmpty(searchVal)) {
        params = [customerInfo.id, { firstRecord }];
      } else {
        params = [customerInfo.id, { [selectedCriteria]: searchVal, firstRecord }];
      }

      let result;
      try {
        result = await APIRouter('C360', 'getCustomerVehicles', params, setLoader);
      } catch (e) {
        logger(e);
      }

      //  If we've fetched a partial chunk, then we're probably fully loaded.
      if (API.utils.isValid(result)) {
        setFullyLoaded(result.length < chunkSize);
      }

      const [freshActives, freshInactives] = splitActiveInactiveVehicles(result || []);
      setVehicles([...activeVehicles, ...freshActives]);
      setDeactivatedVehicles([...inactiveVehicles, ...freshInactives]);

      setTableSearchResults(tableSearchResults.concat(freshActives || []));
    }
  };

  const setSearchValue = (value) => {
    //  We allow spaces, but not at either end.

    switch (selectedCriteria) {
      case 'licensePlateNumber':
        if (/^[a-z0-9/-]*$/i.test(value) === false) {
          return false;
        }
        setSearchInputValue(value);
        break;
      case 'year':
        if (/^\d{0,4}$/.test(value) === false) {
          return false;
        }
        setSearchInputValue(value);
        break;
      // case 'make':
      // case 'model':
      // case 'color':
      default:
        if (/^[a-z0-9 /-]*$/i.test(value) === false) {
          return false;
        }
        const val = API.utils.titleCase(value);
        setSearchInputValue(val);
        break;
    }

    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }

    return true;
  };

  const runSearchQuery = async () => {
    const searchVal = searchInputValue.trim();
    const params = [customerInfo.id, { [selectedCriteria || 'licensePlateNumber']: searchVal }];

    if (API.utils.notEmpty(selectedCriteria)) {

      // Make sure we search all the vehicles, regardless of status.
      let matches = [...vehicles, ...deactivatedVehicles];

      // Unless we're fully loaded we can't know we've got all the records
      // that'll match a year, make, model, color. If we are we can filter
      // without talking to the backend again.
      if (fullyLoaded) {
        matches = matches.filter((v) => {
          return v[selectedCriteria]?.toString()?.toUpperCase() === searchVal?.toUpperCase();
        });
        setNumberOfVehicles(matches?.length || 0);
        setTableSearchResults(matches || []);
        setSelectedVehicle(null);
        return;
        /*
      } else if (selectedCriteria === 'licensePlateNumber') {
        // Fully-loaded or not we can scan for this since it's "unique"...
        matches = matches.filter((v) => {
          return v?.licensePlateNumber?.toUpperCase() === searchVal?.toUpperCase();
        });
        if (matches.length) {
          setNumberOfVehicles(matches?.length || 0);
          setTableSearchResults(matches || []);
          if (matches.length === 1) {
            setSelectedVehicle(matches[0]);
          }
          return;
        }
*/
      }
    }

    setSelectedVehicle(null);

    const result = await APIRouter('C360', 'getCustomerVehicles', params, setLoader);

    // (ss) commented out... since we're filtering the concept of fullyLoaded
    // shouldn't really be applied here.
    /*
    //  If we've fetched a partial chunk, then we're probably fully loaded.
    if (API.utils.isValid(result)) {
      const chunkSize = parseInt(env('API_MAX_VEHICLES'), 10) || 100;
      setFullyLoaded(result.length < chunkSize);
    }
    */

    setNumberOfVehicles(result?.length || 0);
    setTableSearchResults(result || []);
  };

  const updateFilterMsg = (model) => {
    const inputField = document.getElementById('SearchCriteria');

    setSelectedCriteria(model.value);
    setSearchInputValue('');
    setButtonDisabled(true);

    let placeholderText = 'Search by ';

    filterCriteria = model.value;

    switch (filterCriteria) {
      case 'licensePlateNumber':
        placeholderText += 'license plate number';
        break;
      case 'year':
        placeholderText += 'vehicle year';
        break;
      case 'make':
        placeholderText += 'vehicle make';
        break;
      case 'model':
        placeholderText += 'vehicle model';
        break;
      case 'color':
        placeholderText += 'vehicle color';
        break;
      default:
        placeholderText += 'license plate number';
        break;
    }

    inputField.setAttribute('placeholder', placeholderText);
  };

  // TODO: Need to know what search criteria to base this search on
  const onSearch = (event) => {
    const value = event.target.value.trim().toLowerCase();
    setTableSearchResults(
      vehicles.filter((vehicle) => {
        const keywords = value.split(' ');
        return keywords.every((keyword) => {
          return [
            'year',
            'make',
            'model',
            'trimDescription',
            'color',
            'license',
            'overrideDescription',
            'overrideVehicleCategory',
            'overrideVehicleSubCategory',
            'overrideYear',
          ].some((key) => {
            const val = vehicle[key];
            return val && val.toString().toLowerCase().includes(keyword);
          });
        });
      })
    );
  };
  const viewExpandedList = () => {
    setIsOpen(!isOpen);
  };

  /*
  useEffect(() => {
    applyCustomerVehicleError && editVehicle();
  }, [applyCustomerVehicleError]);
  */

  useEffect(() => {
    if (!selectedCriteria) {
      return;
    }
    updateFilterMsg(selectedCriteria);
  }, []);

  return (
    <Wrapper>
      <SearchContainer>
        <SearchWrapper>
          <SearchLabel />
          <Select
            defaultValue={selectedCriteria}
            onChange={updateFilterMsg}
            options={filterCriteriaOptions}
            isSearchable={false}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: '100px',
                userSelect: 'none',
              }),
            }}
        />
          <SearchCriteriaInput id="SearchCriteria" value={searchInputValue} onKeyDown={criteriaKeydown} onChange={(e) => { return setSearchValue(e.target.value); }}/>
          {/* <SearchInput onSearch={(event) => onSearch(event)} /> */}
          <HiSearch
            style={{
              position: 'absolute',
              right: '160px',
              bottom: '-25px',
              fontSize: theme.fontSize.xLarge,
            }}
        />
          <SearchButton width="200" buttonName="Search" onButtonClick={(e) => { runSearchQuery(); }} isDisabled={buttonDisabled}/>
        </SearchWrapper>
      </SearchContainer>
      <ActiveVehiclesDetailSection
        title={searchTitle}
        titleButtonsArr={titleButtonsArr}
        isBlocked={isBlocked}
        hasVerticalScroll={height > 480}
        vehicles={tableSearchResults}
        ref={ref}
      >
        <VehicleWrapper>
          {selectedVehicle && (
            <>
              <VehicleDetails isBlocked={isBlocked} editVehicle={editVehicle} selectedVehicle={selectedVehicle} />
              <HorizontalDivider />
            </>
          )}

          { !tableSearchResults.length && (
            <Message errorMessages={constants.NO_RESULTS_SEARCH}
              type={constants.WARNING} fullWidth/>
          )}
          {/* tableSearchResults.filter(v => isVehicleActive(v)).map((c, index) => {
            if (index > 8) return;
            return (
              <Vehicle
                key={index}
                vehicle={c}
                isDetailView={false}
                onSelect={(e, c) => {
                  selectVehicle(e, c);
                  setIsOpen(false);
                }}              />
            );
          }) */}
        </VehicleWrapper>
        <TableWrapper isInGK={API.GK.hasPOS()} onScroll={handleScroll}>
          <Table
            headers={constants.CUSTOMER_ACTIVE_VEHICLES}
            dataResults={tableSearchResults}
            filter
            hasSearch={false}
            tableType={constants.ACTIVE_VEHICLES_TABLE}
            scrollHandler={handleScroll}
            />
        </TableWrapper>
        {/* activeVehicles.length > 9 && (
          <ViewAllVehiclesButtonWrapper>
            <ViewAllVehiclesButton name="View All" onClick={viewExpandedList}>
              <ArrowIcon>{isOpen ? <UpIcon /> : <DownIcon />}</ArrowIcon>
              {`VIEW ${isOpen ? 'LESS' : 'MORE'} VEHICLES`}
            </ViewAllVehiclesButton>
          </ViewAllVehiclesButtonWrapper>
        ) */}
      </ActiveVehiclesDetailSection>
    </Wrapper>
  );
};
export default CustomerVehicles;
